<template>
  <div class="row">
    <div class="card col-3">
      <div class="card-body">
        <vue-qrcode v-if="qr_code !== null" :value="qr_code" />
        <h4 class="textAlign-center">VARS 2 FACTOR AUTHENTICATION</h4>
        <p>Please scan the above barcode using Google Authentication</p>
        <input
          type="text"
          v-on:keyup.enter="authenticate"
          v-model="otp"
          maxlength="6"
          class="form-control"
        />
        <p v-if="error" class="text-danger">Failed to authenticate</p>
        <div class="div-center">
          <button
            @click="authenticate"
            class="btn btn-primary margin-auto m-t-xs"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueQrcode from "@chenfengyuan/vue-qrcode";
import AuthService from "../../services/auth.service";
export default {
  name: "2fa",
  components: {
    VueQrcode,
  },
  props: {
    route: String,
    params: {
      type: Object,
      required: false,
    },
    first: Boolean,
  },
  data() {
    return {
      secret: "", //secret - what's given to google auth for it to generate time based otp
      qr_code: null,
      otp: "",
      error: false,
      waiting: false,
    };
  },
  mounted() {
    this.loadQR();
  },
  methods: {
    loadQR() {
      console.log("route: ", this.route);
      console.log("first: ", this.first);
      AuthService.getQR()
        .then((response) => {
          this.qr_code = `${response.data.otpauthurl}`;
          console.log(this.qr_code);
        })
        .catch((error) => {
          console.log(error);
          this.$error(
            "Unable to geneate a QR Code at this time. Please try again."
          );
        });
    },
    authenticate() {
      this.error = false;
      this.waiting = true;
      AuthService.verify2fa(this.otp)
        .then(() => {
          this.waiting = false;
          console.log("route: ", this.route, "router: ", this.router);
          if (this.route !== null && this.router !== undefined) {
            this.$router.push({ name: this.route, params: this.params });
          } else {
            this.$router.push("/");
          }
        })
        .catch(() => {
          this.$error("2FA Validation failed");
          this.error = true;
          this.waiting = false;
        });
    },
  },
};
</script>

<style scoped>
.row {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 90vh;
}
</style>
